
import ResizeObserver from "react-resize-observer";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Carousel
        , Grid
        , SearchBar // the search bar the user will type into
        //, SearchContext // the context that wraps and connects our components
        , SearchContextManager // the context manager, includes the Context.Provider
        //, SuggestionBar // an optional UI component that displays trending searches and channel
 } from "@giphy/react-components";
import { 
  useEffect,
  useRef,
  //useContext,
   useState } from "react";
import { allowImages } from "@/lib/utils/allowImages";
import { CHAT_IMAGE_PERMS } from "@/lib/config";
import { hasData } from "@/lib/utils/hasData";
;

const giphyFetch = new GiphyFetch(process.env.NEXT_PUBLIC_GIPHY_API_KEY);

// the search experience consists of the manager and its child components that use SearchContext
const Giphy = ({
  onGifClick
    , view
    , msg
    , profiledata
}) => {



  //check to see if this is enabled, otherwise return an error message
  if (!allowImages(profiledata?.bol_chat_image,
                  profiledata?.bol_chat_image_bitarray,
                  "gif",
                  CHAT_IMAGE_PERMS) ) {
    return (<>Sorry! Chat gifs have been disabled by {profiledata?.user_name}.</>)
  }
  
  return (<><SearchContextManager 
      apiKey={process.env.NEXT_PUBLIC_GIPHY_API_KEY}
      initialTerm={msg}
      shouldDefaultToTrending={true}

    >
        <GiphyComponents 
         onGifClick={onGifClick}
          view={view}
          msg={msg}
        />
    </SearchContextManager></>)
  
  
  }

function GiphyComponents({ 
      onGifClick
    , view
    , msg
   }) {

    const searchRef = useRef(null)

    useEffect(()=>{
      if (searchRef?.current) searchRef?.current?.focus();
    },[
      searchRef?.current
    ])

    //const { fetchGifs, searchKey } = useContext(SearchContext)

    const [width, set_width] = useState(window.innerWidth-80);
    const [search,set_search] = useState(msg || '');

    const fetchGifs = (offset: number) =>  (!hasData(search)) 
                                            ? giphyFetch.trending({ offset, limit: 10 })
                                            : giphyFetch.search(search, { offset, limit: 10 })
      



  if (view=='carousel') {
    return (<>
    <div className='flex flex-col w-full' style={{maxWidth: width}}>
      <div className='sticky top-0'>
        <input
          autoFocus={true}
          type='text'
          ref={searchRef}
          className='w-full border border-gray-700 bg-gray-700 text-xl  mb-2 focus:outline-none outline-white'
          onChange={(e) => set_search(e.target.value)}
          value={search}
          placeholder={'Search for a Gif'}
        />
        {/* <SearchBar
          placeholder={'Search for a Gif'}
          autoFocus={true}
          className={' border border-gray-700 bg-gray-500 text-3xl font-bold mb-2 focus:outline-none'}
        /> */}
        {/* <SuggestionBar 
        
        /> */}
      </div>
      <div className='w-full flex-1'>
      <Carousel 
        key={search}
        onGifClick={onGifClick}
        fetchGifs={fetchGifs} 
        gifHeight={200} 
        gutter={6} 
      />
      <ResizeObserver
          onResize={({ width }) => {
            if (width > 0) set_width(width);
          }}
        />
      </div>
    </div>
    </>);
  }

  if (view=='grid') {
    return (
      <>
      <div className="sticky top-0">
        <SearchBar
          placeholder={'Search for a Gif'}
          autoFocus={true}
          className={' border  border-gray-700  bg-gray-500 text-xl font-bold mb-2'}
        />
      </div>

        <Grid
          key={search}
          onGifClick={onGifClick}
          fetchGifs={fetchGifs}
          width={width}
          columns={3}
          gutter={6}
        />
        <ResizeObserver
          onResize={({ width }) => {
            set_width(width);
          }}
        />
      </>
    );

  }
}

export default Giphy